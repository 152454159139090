@font-face {
  font-family: "Metamorphous";
  src: url("./common-components/assets/fonts/Metamorphous/Metamorphous-Regular.ttf");
}
@font-face {
  font-family: "Mate SC";
  src: url("./common-components/assets/fonts/Mate_SC/MateSC-Regular.ttf");
}

body {
  background-color: #2a2a2a;
}

nav {
  width: 100%;
  height: 90px;
  background: #2a2a2a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar-container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.subbar-left {
  margin-left: 5%;
  width: 90%;

  display: flex;
  align-items: center;
}

.subbar-right {
  margin-right: 5%;
  width: auto;

  font-family: "Mate SC";
  font-style: normal;
  font-weight: normal;
  font-size: 21pt;
  text-align: center;

  display: flex;
  align-items: center;

  color: #b79a63;
}

.custom-link {
  font-family: "Mate SC";
  font-style: normal;
  font-weight: normal;
  font-size: 21pt;
  text-align: center;

  display: flex;
  align-items: center;
  text-decoration: none;
  color: #b79a63;
}

.favicon {
  margin-top: 0.2%;
  width: 78px;
}

.nav-title {
  width: 10%;
  margin-left: 1.5%;

  font-family: "Metamorphous";
  font-style: normal;
  font-weight: normal;
  font-size: 28pt;

  text-align: center;
  color: #b79a63;
}

.divzona {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  margin-top: 4%;
  margin-bottom: 9.15%;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 5%;
  padding-right: 5%;
  background: #303030;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.5);
  width: 81%;
}

.post-info {
  margin-bottom: 2%;
}

.author {
  font-family: "Mate SC";
  font-style: normal;
  font-weight: normal;
  font-size: 14pt;
  text-align: justify;
  color: #ffffff;
}

.city {
  font-family: "Mate SC";
  font-style: normal;
  font-weight: normal;
  font-size: 14pt;
  text-align: justify;
  color: #ffffff;
}

.date {
  font-family: "Mate SC";
  font-style: normal;
  font-weight: normal;
  font-size: 14pt;
  text-align: justify;
  color: #626262;
}

h1 {
  font-family: "Metamorphous";
  font-style: normal;
  font-weight: normal;
  font-size: 35pt;
  color: #b79a63;
}

article {
  font-family: "Mate SC";
  font-style: normal;
  font-weight: normal;
  font-size: 20pt;
  text-align: justify;
  color: #ffffff;
}

.custom-list {
  padding-left: 2%;
}

ol {
  counter-reset: item;
  list-style: none;
}
ol li:before {
  content: counter(item, lower-roman) ".";
  color: #b79a63;
  font-size: 25pt;
  counter-increment: item;
  position: absolute;
  margin-left: -45px;
  margin-top: -0.5%;
}
ol li {
  margin: 3% 0 0 0;
}

footer {
  display: flex;
  flex-direction: row-reverse;
}

.signature {
  text-align: center;
  margin-right: 2%;
}

name {
  color: #b79a63;
}

contact {
  color: #626262;
}
